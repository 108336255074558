import { ItemKinds } from 'types';

export const INSURANCE = {
  URL: '/images/assurant.svg',
  ALT: 'Assurant Logo',
  ITEM_TYPE: 'resident-onboarding-renters-insurance',
};

export const ALERT = {
  VALIDATING:
    'We are reviewing your information. We will follow up with you shortly.',
  COMPLETED: 'Accepted by your building',
};

export const ERROR = {
  LOADING: 'There was an error loading your questions. Please try again later.',
  ON_SUBMIT: 'There was an issue while submitting the form. Please try again.',
};

export const THANK_YOU = {
  title: 'Thank you!',
  subtitle:
    'Our staff will review and let you know if we need anything else from you.',
};

export const REJECTION = {
  petsTitle: 'Your pet registration needs attention',
  vehicleTitle: 'Your vehicle registration needs attention',
  insuranceTitle: 'Your proof of insurance needs attention',
  moveCostsTitle: 'Your move-in cost payment needs attention',
  subtitle:
    'Your leasing agent reviewed your submission and found some action items for you.',
};

export const PETS_ROUTE = '/resident-onboarding/pets';
export const VEHICLE_REGISTRATION_HOSTED_ROUTE =
  '/resident-onboarding/vehicles/hosted';

export const ITEM_PREFIX = 'resident_onboarding';

const UTILS_URL_PREFIX = '/resident-onboarding/proof-of-utilities';

export const UtilsRoutes = {
  HOME: '/',
  HUB: `${UTILS_URL_PREFIX}`,
  SET_UP: `${UTILS_URL_PREFIX}/set-up`,
  PROVIDERS: `${UTILS_URL_PREFIX}/providers`,
  SUMMARY: `${UTILS_URL_PREFIX}/proof-submitted`,
  INFO_REQ: `${UTILS_URL_PREFIX}/info-requested`,
};

export const REPLACE_TEXT = '{service}';

export const UTILS_ITEM_PREFIX = `${ITEM_PREFIX}-proof-of-`;

export const REJECT_PAGE_ITEM_KINDS_LIST = [
  ItemKinds.RESIDENT_ONBOARDING_PET_REGISTRATION,
  ItemKinds.RESIDENT_ONBOARDING_MOVE_IN_COSTS,
  ItemKinds.RESIDENT_ONBOARDING_VEHICLE_REGISTRATION,
  ItemKinds.RESIDENT_ONBOARDING_VEHICLE_REGISTRATION_HOSTED,
  ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_INSURANCE,
] as const;

export const ApprovalReason = {
  COMPLETED: 'Completed successfully',
  COMPLETED_OUTSIDE: 'Completed outside of Updater',
  COMPLETED_BY_ROOMMATE: 'Completed by roommate',
  NOT_NEEDED: 'No longer needed',
  OTHER: 'Other',
};
