import camelcaseKeys from 'camelcase-keys';
import { ObjectTyped } from 'utils/ts-helpers/object-typed';
import { PETS_ROUTE } from '../constants/constants';
import {
  PetData,
  PetsItemConfiguration,
  PetsStructuredSubmit,
  Question,
  Submission,
} from '../constants/types';
import { loadPreviousPetsSubmission } from './pets-submission';

const KEY_SLICE_ARRAY = 'has-pet';
const MIN_NUMBER_OF_PETS = 1;
/**
 * checks if the value is undefined, is an empty string or array.
 * If yes returns false otherwise returns true
 * @returns boolean
 * */
const answerHasValue = (
  answer: undefined | string[] | string | number | boolean
) => {
  return !(
    answer === undefined ||
    (Array.isArray(answer) && answer.length === 0) ||
    (typeof answer === 'string' && answer.length === 0)
  );
};

export const buildSubmissionDataWithAllFields = (
  submission: Partial<PetsStructuredSubmit>
): PetsStructuredSubmit => {
  return {
    has_pet: undefined,
    pet_type: undefined,
    name: undefined,
    breed: undefined,
    age: undefined,
    weight: undefined,
    photo_files: [],
    vax_files: [],
    is_esa: undefined,
    esa_files: [],
    id: undefined,
    ...submission,
  };
};

export const isPetsAbandoned = (petsData: Array<PetData>): boolean =>
  petsData?.some((pData) => pData?.isAbandoned);

export const isPetsAllAnswersComplete = (petsData: Array<PetData>): boolean => {
  /** if a user got to the end and didn't press "finish"
   this is a helper for showing the "almost done" badge in essentials. */
  // When invoked on an empty array, every() returns true.
  return petsData.length === 0
    ? false
    : petsData?.every(
        (pData) =>
          pData?.isAllAnswersProvided || pData.formValues.has_pet === false
      );
};

export const isPetDraftComplete = (
  submission: PetsStructuredSubmit
): boolean => {
  if (!submission) {
    return false;
  }
  const reconstructedSubmission = buildSubmissionDataWithAllFields(submission);

  const structuredSubmissionKeys = ObjectTyped.keys(reconstructedSubmission);

  // If is_esa is false then esa_files arent required or shown as a form option to the user
  const submissionKeys = reconstructedSubmission.is_esa
    ? structuredSubmissionKeys
    : structuredSubmissionKeys?.filter((k) => k !== 'esa_files');

  return submissionKeys?.every((stSubmissionKey) => {
    const value = reconstructedSubmission[stSubmissionKey];

    return answerHasValue(value);
  });
};

export const getAnsweredQuestions = (
  submission: PetsStructuredSubmit
): Array<string> => {
  const reconstructedSubmission = buildSubmissionDataWithAllFields(submission);

  const structuredSubmissionKeys = ObjectTyped.keys(reconstructedSubmission);

  const answeredQuestionsArray: Array<string> = [];

  structuredSubmissionKeys.forEach((stSubmissionKey) => {
    const value = reconstructedSubmission[stSubmissionKey];
    if (answerHasValue(value)) {
      answeredQuestionsArray.push(stSubmissionKey);
    }
  });

  return answeredQuestionsArray;
};

export const isPetAbandoned = (
  petSubmission: PetsStructuredSubmit
): boolean => {
  const answeredQuestions = getAnsweredQuestions(petSubmission);
  return isPetDraftComplete(petSubmission)
    ? false
    : /** we consider the flow abandoned once a user answers name/breed questions and beyond */
      !!(
        answeredQuestions?.includes('name') &&
        answeredQuestions?.includes('breed')
      );
};

const legacyGetMaxPetsNumber = (questions?: Question[]): number => {
  if (!questions) return MIN_NUMBER_OF_PETS;
  return (
    camelcaseKeys(questions, {
      deep: true,
    })?.filter((question: Question) =>
      question?.code?.includes(KEY_SLICE_ARRAY)
    ).length || MIN_NUMBER_OF_PETS
  );
};

export const getMaxNumberOfPets = (
  petsItemConfiguration: PetsItemConfiguration
): number => {
  let maxPetsNumber =
    petsItemConfiguration?.max_number || petsItemConfiguration?.max_pets;
  if (!maxPetsNumber) {
    maxPetsNumber = legacyGetMaxPetsNumber(petsItemConfiguration?.questions);
  }
  return maxPetsNumber;
};

export const getPetsData = (
  petsItemData: {
    [s in 'metadata']: {
      submission: Submission;
    };
  },
  petsItemConfiguration: PetsItemConfiguration
): {
  petsData: Array<PetData>;
  currentQuestion: string;
  maxPetsNumber: number;
  directions?: string;
  note?: string;
} => {
  const { metadata } = petsItemData || {};
  const submission = metadata?.submission;
  const directions = petsItemConfiguration?.directions;
  const note = petsItemConfiguration?.note;
  // eslint-disable-next-line no-underscore-dangle
  const currentQuestion = submission?.__current_question;
  const maxPetsNumber = getMaxNumberOfPets(petsItemConfiguration);

  const submittedValues = loadPreviousPetsSubmission(
    submission,
    submission?.structured_submit,
    maxPetsNumber
  );

  const petsData = submittedValues?.map((petSubmission, index): PetData => {
    const petId = index + 1;
    return {
      isAllAnswersProvided: isPetDraftComplete(petSubmission),
      isAbandoned: isPetAbandoned(petSubmission),
      formValues: buildSubmissionDataWithAllFields({
        ...petSubmission,
        id: petId,
      }),
      resumeFromScreen: currentQuestion,
      answeredQuestions: getAnsweredQuestions(petSubmission),
    };
  });

  return { petsData, maxPetsNumber, currentQuestion, directions, note };
};

export const getCurrentPetNumber = ({
  petsData,
  maxPetsNumber,
  currentQuestion,
  pathName,
}: {
  petsData: Array<PetData>;
  currentQuestion: string | undefined;
  maxPetsNumber: number;
  pathName: string;
}) => {
  let currentPetNumber: number;

  if (!currentQuestion) {
    currentPetNumber = 1;
  } else if (pathName === PETS_ROUTE) {
    const notAllAnswersProvidedPet = petsData?.find(
      (petData) => !petData.isAllAnswersProvided
    )?.formValues?.id;
    if (notAllAnswersProvidedPet) currentPetNumber = notAllAnswersProvidedPet;
    else {
      currentPetNumber = petsData?.length + 1;
    }
  } else if (pathName === `${PETS_ROUTE}/summary`) {
    currentPetNumber = petsData?.length;
  } else {
    const currentQuestionArr = currentQuestion.split('-');
    const extractedPetNumber = parseInt(
      currentQuestionArr[currentQuestionArr.length - 1] ?? '',
      10
    );

    currentPetNumber = Number.isNaN(extractedPetNumber)
      ? petsData.length
      : extractedPetNumber;
  }
  // this is for an edge case if the user navigates to "/" manually
  return currentPetNumber > maxPetsNumber ? maxPetsNumber : currentPetNumber;
};
